







































import Vue from "vue";
import Component from "vue-class-component";
import wx from "weixin-js-sdk";
import * as qiniu from "qiniu-js";
import { Field, Button, Uploader, Icon, Checkbox, CheckboxGroup, Dialog, Toast, Radio, RadioGroup } from "vant";

@Component({
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Dialog.Component.name]: Dialog.Component
  }
})
export default class UserRepair extends Vue {
  qrCode = "";
  type = 0;
  typeMsg = "";
  reason = 0;
  desc = "";
  cabinetId = 0;
  cabinetRoomId = 0;
  batteryOrderId = 0;
  replaceOrderId = 0;

  reasonList = [];

  dialog = {
    show: false,
    qrCode: ""
  };
  qiniuToken = "";
  fileList = [] as { file: File; url: string; key: string; isImage: boolean }[];

  created() {
    this.$readyWx(this.$axios, ["scanQRCode"]);
    this.getQiniuToken();
    this.batteryOrderId = (this.$route.query.batteryOrderId || 0) as number;
    this.replaceOrderId = (this.$route.query.replaceOrderId || 0) as number;
    const qrCode = (this.$route.query.qrCode || "") as string;
    const type = (this.$route.query.type || 0) as number;
    if (qrCode) {
      this.updateQrCode(qrCode, type);
    }
  }

  // ---
  get isDetailShow() {
    return this.qrCode && this.reason;
  }
  get isSaveReady() {
    return this.qrCode && this.reason && !(this.reason == 1 && !this.desc);
  }

  // ---
  save() {
    const keys = [];
    for (const f of this.fileList) {
      if (!f.key) {
        Toast.fail("存在上传未成功的照片");
        return;
      }
      keys.push(f.key);
    }
    if (this.cabinetId == 0) {
      Toast.fail("请先扫描或填写故障设备二维码");
      return;
    }
    const data = {
      cabinetId: this.cabinetId,
      cabinetRoomId: this.cabinetRoomId,
      reasons: this.reason,
      desc: this.desc,
      imageIds: keys.join(","),
      batteryOrderId: this.batteryOrderId,
      replaceOrderId: this.replaceOrderId
    };
    this.$axios.post("/api/manage/cabinet/requestRepair", data).then(() => {
      Toast.success({
        message: "提交成功",
        onClose: () => {
          wx.miniProgram.navigateBack({ delta: 1 });
        }
      });
    });
  }
  scan() {
    this.$scanCode(content => {
      if (!content.startsWith("http")) {
        Toast.fail("无效的二维码");
        return;
      }
      const cs = content.split("?c=");
      if (cs.length != 2) {
        Toast.fail("二维码格式有误");
        return;
      }
      const prefix = cs[0];
      const code = cs[1];
      let type = 0;
      if (prefix.endsWith("room")) {
        type = 2;
      } else if (prefix.endsWith("cabinet")) {
        type = 1;
      } else {
        Toast.fail("不支持的设备");
        return;
      }
      this.updateQrCode(code, type);
    });
  }
  updateQrCode(qrCode: string, qrCodeType = 0) {
    if (!qrCode) {
      Toast.fail("请先扫描或填写故障设备二维码");
      return;
    }
    this.$axios.post("/api/manage/cabinet/searchQrCode", { qrCode: qrCode, qrCodeType: qrCodeType }).then(e => {
      const data = e.data.data;
      this.qrCode = qrCode;
      this.cabinetId = data.cabinetId;
      this.cabinetRoomId = data.cabinetRoomId;
      this.reasonList = data.reasonList;
      this.type = data.qrCodeType;
      if (this.type == 1) {
        this.typeMsg = "设备";
      } else if (this.type == 2) {
        this.typeMsg = "通道";
      }
    });
  }

  /**
   * 图片上传
   */
  getQiniuToken() {
    this.$axios.post("/api/manage/getQiniuToken", {}).then(res => {
      this.qiniuToken = res.data.data;
    });
  }
  onPickFile(file: { file: File; status: string; key: string }) {
    if (Array.isArray(file)) {
      for (const f of file) {
        this.onPickFile(f);
      }
      return;
    }
    file.status = "uploading";
    const options = {
      quality: 0.92,
      noCompressIfLarger: true
    };
    qiniu.compressImage(file.file, options).then(data => {
      const observable = qiniu.upload(data.dist as File, file.key, this.qiniuToken);
      observable.subscribe({
        complete(res) {
          file.status = "done";
          file.key = res.key;
        },
        error() {
          file.status = "error";
        }
      });
    });
  }
}
